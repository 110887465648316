import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { LookupService } from '../../../../services/lookup/lookup.service';
import { DropdownComponent } from '../dropdown.component';

@Component({
  selector: 'app-ui-generic-dropdown',
  templateUrl: './generic-dropdown.component.html',
  styleUrls: ['../../../../../scss/components/ui/dropdown/generic-dropdown.component.scss']
})
export class GenericDropdownComponent extends DropdownComponent implements OnInit, OnChanges {

  @Output() dropdownListUpdated = new EventEmitter();

  custom = '';
  @Input() customList = undefined;

  constructor(public router: Router, public lookupService: LookupService) { super(); }

  ngOnInit(): void {
    this.list = this.options;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.options && changes.options.currentValue !== changes.options.previousValue) {
      this.list = this.options;
    }
  }
  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
    this.dropdownResponse.emit(this.current);
    this.custom = '';
  }
  addCustom() {
    if(!this.customList.includes(this.custom) && !this.list.includes(this.custom)) {
      this.customList.push(this.custom);
      this.dropdownListUpdated.emit(this.customList);
    }
  }
  removeCustom($event, r) {
    $event.stopPropagation();
    if(this.current === r) {
      this.current = this.list[0] ?? '';
    }
    this.customList = this.customList.filter((i) => i !== r);
    this.dropdownListUpdated.emit(this.customList);
  }
}