<div class="flex-row balance">
  <div class="flex-row left">
    <app-asset class="asset"
      [meta]="!token ? '../../../../assets/img/tezos-xtz-logo.svg' : token?.thumbnailAsset || token?.displayAsset" [class.sd]="!token" (click)="viewToken()">
    </app-asset>
    <div class="flex-row title" (click)="viewToken()">{{!token ? 'Tezos' : token.symbol}}</div>
    <div class="flex-column prices">
      <div class="price">{{ token ? (getBalance() | number: '1.0-' + (token?.decimals || 6) | removeComma) : getBalance() }}</div>
      <div class="price-usd">{{ getBalanceFiat() ? '$' + (getBalanceFiat() | number:'1.2-2' | removeComma) : '—' }}</div>
    </div>
  </div>
  <div class="flex-row right">
    <app-send-button [activeAccount]="account"
      [tokenTransfer]="!token ? null : token?.contractAddress + ':' + token?.id"
      [symbol]="!token ? 'tez' : token?.symbol"></app-send-button>
  </div>
</div>