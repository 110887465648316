<button class="scan" (click)="openModal()">Scan QR code</button>
<div *ngIf="isOpen" class="flex-column kukai-modal">
  <div class="content">
    <div class="flex-column top">
      <div class="flex-row button close" (click)="closeModal()"><img class="x" src="../../../assets/img/close-btn-x.png" /></div>
      <h1 class="title">QR-Scanner</h1>
    </div>
    <div class="seperator"></div>
    <div class="flex-column qr-scanner">
      <!-- <label class="value">Scan QR code from dApp</label> -->
      <div class="video-container">
        <video #videoPlayer>
          Browser not supported
        </video>
        <p class="no-cam" *ngIf="!qrScanner">No camera detected</p>
      </div>
      <input *ngIf="!env.production" type="text" [(ngModel)]="manualInput" class="text"
        (paste)="handlePaste($event)" placeholder="...or paste pairing string here">
    </div>
  </div>
</div>