<div class="selectable main-container">
  <div class="top-container-activity">
    <h1>Recent Activity</h1>
    <div class="right">
      <a class="view-all" href="{{ explorerURL(account.address) }}" target="_blank">View in Explorer</a>
      <!-- <img class="button icon" src="../../../../assets/img/info.svg" /> -->
    </div>
  </div>
  <div class="selectable" id="operations-container" *ngIf="this.translate.store.translations.en">
    <ng-container *ngIf="account.activities; else loading">
      <ng-container *ngIf="!account.activities.length">
        <div class="line"><span>No activity yet</span></div>
      </ng-container>
      <ng-container *ngFor="let activity of account.activities | slice:0:10; trackBy: trackEvent">
        <app-event [activity]="activity" [account]="account"></app-event>
      </ng-container>
    </ng-container>
    <ng-template #loading>Loading activity...</ng-template>
  </div>
</div>