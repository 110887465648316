import { Component } from '@angular/core';

@Component({
  templateUrl: '404.component.html',
  styleUrls: ['../../../../scss/components/views/error/error.scss']
})
export class P404Component {

  constructor() { }

}
