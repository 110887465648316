<div *ngIf="isOpen" class="flex-column kukai-modal">
  <div class="content">
    <div class="flex-column top">
      <div class="flex-row button close" (click)="close()"><img class="x"
          src="../../../../../assets/img/close-btn-x.png" />
      </div>
      <h1 class="title">Token Info</h1>
    </div>
    <div class="flex-column token-info">
      <div class="flex-row data">
        <div class="flex-column wrapper" [class]="imageExpanded ? 'expanded' : ''">
          <app-asset [style.display]="imageExpanded ? 'none' : ''" class="flex-row button sd-only"
            [meta]="!token ? '../../../assets/img/tezos-xtz-logo.svg' : token?.displayAsset || token?.thumbnailAsset"
            [size]="'600x600'" (click)="expandImage()" (loaded)="assetLoaded = true"></app-asset>
          <app-asset [style.display]="!imageExpanded ? 'none' : ''" class="flex-row button sd-only"
            [meta]="!token ? '../../../assets/img/tezos-xtz-logo.svg' : token?.displayAsset || token?.thumbnailAsset"
            [size]="'raw'" (click)="expandImage()"></app-asset>
          <img *ngIf="imageExpanded" class="flex-row button close" (click)="expandImage()"
            src="../../../../../assets/img/close-btn-x.png" />
          <img *ngIf="!imageExpanded && assetLoaded" class="expand" src="../../../../../assets/img/expand.png"
            (click)="expandImage()" />
        </div>
        <div class="flex-column meta">
          <div class="flex-column top">
            <div *ngIf="token?.name" class="flex-column key-value">
              <div class="key">
                name
              </div>
              <div class="value">
                {{ token?.name }}
              </div>
            </div>
            <div *ngIf="!isNFT && token?.symbol" class="flex-column key-value">
              <div class="key">
                symbol
              </div>
              <div class="value">
                {{ token?.symbol }}
              </div>
            </div>
            <div *ngIf="!isNFT && !!token?.decimals" class="flex-column key-value">
              <div class="key">
                decimals
              </div>
              <div class="value">
                {{ token?.decimals }}
              </div>
            </div>
            <div *ngIf="token?.description" class="flex-column key-value desc">
              <div class="key">
                description
              </div>
              <div class="value">
                {{ token?.description }}
              </div>
            </div>
            <div *ngIf="token?.creator" class="flex-column key-value">
              <div class="key">
                creator
              </div>
              <div class="value">
                {{ token?.creator }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!!token" class="flex-row button more-info" [class]="moreInfo ? 'open' : ''" (click)="expand()">
        <span>{{ moreInfo ? 'LESS' : 'MORE' }} INFO</span><img src="../../../assets/img/chevron-down.svg" />
      </div>
      <div *ngIf="!!token" [style.maxHeight]="moreInfo ? (3.25 * Object.keys(tokenFiltered).length) + 'rem' : ''"
        [style.overflowY]="autoOverflow ? 'auto' : ''" class="flex-column meta-more">
        <ng-container *ngFor="let key of Object.keys(tokenFiltered)">
          <div *ngIf="key === 'contractAddress'" class="flex-column key-value">
            <div class="key">
              {{ 'CONTRACT (ID)' }}
            </div>
            <div class="value">
              {{ token[key] + ' ' + '(' + token['id'] + ')' }}
            </div>
          </div>
          <div *ngIf="key !== 'contractAddress' && key !== 'description' && key !== 'id' && !!token[key]"
            class="flex-column key-value">
            <div class="key">
              {{key}}
            </div>
            <div class="value">
              {{ token[key] }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="!token?.isUnknownToken" class="seperator"></div>
    <div *ngIf="!token?.isUnknownToken" class="flex-column bottom">
      <app-send-button [override]="true" [activeAccount]="activeAccount"
        [tokenTransfer]="!token ? null : token['contractAddress']+':'+token['id']"
        [symbol]="!token ? 'tez' : token?.symbol">
      </app-send-button>
    </div>
  </div>
</div>