<div *ngIf="isOpen" class="flex-column kukai-modal">
  <div class="content">
    <div class="flex-column top">
      <div class="flex-row button close" (click)="closeModal()"><img class="x" src="../../../assets/img/close-btn-x.png" /></div>
      <h1 class="title">Add New Account</h1>
    </div>
    <div class="flex-column new-account">
      <div class="label">
        ENTER YOUR PASSWORD TO CONFIRM
      </div>
      <input #pwdInput [(ngModel)]="password" class="text password" autocomplete="current-password"
        placeholder="Password" type="password" (input)="this.errorMsg = ''" (change)="this.errorMsg = ''" (keydown.enter)="addPkh()">
      <span *ngIf="this.errorMsg" class="danger margin">{{ this.errorMsg }}</span>
      <div class="seperator"></div>
      <div class="flex-column bottom">
        <button class="confirm" (click)="addPkh()">Confirm</button>
      </div>
    </div>
  </div>
</div>