import { Component } from '@angular/core';

@Component({
  templateUrl: '500.component.html',
  styleUrls: ['../../../../scss/components/views/error/error.scss']
})
export class P500Component {

  constructor() { }

}
