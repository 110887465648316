<div class="flex-row token" (click)="viewToken(token)">
  <div class="dot"></div>
  <div class="wrapper">
    <app-asset class="asset" (inView)="balanceChangeTimer()" [meta]="token?.thumbnailAsset || token?.displayAsset"></app-asset>
    <div *ngIf="Number.parseInt(token?.balance) > 1" class="balance">x{{
      formatBalance(token) }}</div>
  </div>
  <div class="flex-column right">
    <div class="title">
      {{token?.name}}
    </div>
    <div *ngIf="token?.series" class="series">
      {{ token?.series }}
    </div>
  </div>
</div>