<div class="flex-row dropdown" [class.expanded]="isOpen" [id]="ecmpId">
  <div class="flex-row base" (click)="toggleDropdown()">
    <span class="current">{{ current }}</span><img class="button icon-db"
      src="../../../../assets/img/down-arrow.svg" />
  </div>
  <div class="flex-column dropdown-content">
    <div class="flex-column body">
      <ng-container *ngFor="let item of list">
        <span class="option" (click)="current = item; toggleDropdown()">{{ item }}</span>
      </ng-container>
      <ng-container *ngFor="let item of customList">
        <span class="flex-row option custom" (click)="current = item; toggleDropdown()">{{ item }}<button (click)="removeCustom($event, item)">Remove</button></span>
      </ng-container>
    </div>
    <span *ngIf="customList" class="flex-row option"><input [(ngModel)]="custom" class="custom" placeholder="Custom" value="" /><button (click)="addCustom()">Add</button></span>
  </div>
</div>