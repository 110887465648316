<div class="legal">
  <H1>Privacy Policy</H1>
  <H5>Introduction</H5>
  <p>Kukai Wallet (“hereinafter Kukai” “we” “our” “us”) is committed to ensuring the privacy of all our users. We take
    our duty to process your personal data very seriously. This policy represents our commitment to your right to
    privacy, giving you a clear explanation about how we use your information and your rights over that information. We
    strive to be complaint with any data or privacy laws and regulations such as the California Consumer Privacy Act
    (“CCPA”) and General Data Protection and Regulation (“GDPR”).</p>

  <p>It's possible to use https://kukai.app/ (“hereinafter website”) without submitting any personal information. </p>

  <H5>Scope of Privacy Policy</H5>
  <p>This privacy policy applies only to your use of our website. Our website and Services may contain links to other
    websites posted by members via images, content such as videos, links to other websites which are not hosted by us.
    Please note that we have no control over how your data is collected, stored, or used by other websites or service
    providers and we advise you to check the privacy policies of any such website(s) before providing any data to them.
  </p>

  <p>What types of information do we collect and how?
  <p>The type and amount of information we receive and store depends on how you use the website and Services. We do not
    store any information as all data is localized on the User’s computer or device. You can access the website and
    Services without telling us who you are and without revealing any personal information.</p>

  <p>Data protection laws require us to have a legal justification to process your personal information. We do not
    collect any personally identifiable data from Users, nor do we store or maintain any of such data. All data is
    localized on the User’s computer or device.</p>

  <H5>Sharing of data</H5>
  <p>In some circumstances your information may be shared with us by third parties, but only where you have specifically
    consented.</p>

  <p>We never sell user information to anyone.</p>

  <H6>In order to operate the site, the following data sharing occurs:</H6>

  <p>Our tech-related service providers may necessarily have access to user data, and could collect/store data beyond
    what Kukai Wallet itself does. Third Party Service providers may or may not include, and are not limited to:
    CoinGecko, Nautilus Cloud, and Tezos Giga Node. Please refer to their respective privacy policies and data
    collection policies.</p>

  <H5>Other sharing</H5>
  <p>We may also share your personal information with your permission, or if we are legally required to disclose your
    information in circumstances where this cannot be reasonably resisted.</p>

  <H5>Legal obligation</H5>
  <p>We will process your personal information to fulfil any legal obligations placed upon us, through a lawfully valid
    legal order by a court of competent jurisdiction.</p>

  <H5>Jurisdiction</H5>
  <p>Our website and Services provided is under U.S. jurisdiction and is subject to U.S. subpoenas, wiretap orders,
    preservation orders (which would negate the above retention rules), and similar. Furthermore, our service
    providers could also be subject to similar orders without our knowledge.</p>

  <H5>Security</H5>
  <p>We take appropriate security measures to ensure that we keep your limited information secure, accurate and up
    to date. However the transmission of information over the Internet is never completely secure, so while we do
    our best to protect personal information, we cannot guarantee the security of information transmitted to our
    website.</p>

  <H5>Hosting and processing arrangements</H5>
  <p>Our website is hosted by third party service providers and therefore any personal details you submit through
    them may be processed by that third party service provider. Please see the above for the third party service
    providers.</p>

  <H5>Retention Period For Data</H5>
  <p>All User data is localized and retained by the User’s computer or device. We do not retain any User
    information.</p>

  <H5>Complaints</H5>
  <p>If you wish to lodge a complaint about our handling of your personal data please get in touch with us on the
    details above with the details of your complaint; we aim to respond to all complaints within 14 working days.
    All legal requests will be reviewed by our legal department and appropriate action will be taken. If you have a
    legal request, please contact legal@kukai.app</p>

</div>