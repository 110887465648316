<div class="container">
    <div>
        <img src="../../../assets/img/torus-login/{{verifier()}}-color.svg" width="24">
        <h4>{{displayName()}}</h4>
        <p>{{activeAccount.pkh}}</p>
    </div>
    <div>
        <h4>BALANCE:</h4>
        <h3>{{activeAccount.balanceXTZ || 0}} XTZ</h3>
    </div>
</div>
