<div *ngIf="permissionRequest" class="flex-column kukai-modal">
  <div class="content">
    <div class="flex-column top">
      <div class="flex-row button close" (click)="rejectPermissions()"><img class="x" src="../../../assets/img/close-btn-x.png" /></div>
      <h1 class="title">{{ permissionRequest.appMetadata.name }}</h1>
    </div>
    <div class="seperator"></div>
    <div class="flex-column permission-request">
      <ng-container *ngIf="cachedIcon(permissionRequest); let cachedIcon;"><app-asset class="app-icon" [meta]="cachedIcon"></app-asset></ng-container>
      <h4>Permissions requests:</h4>
      <div class="flex-row-wrap requests">
        <span class="flex-row" *ngFor="let scope of permissionRequest.scopes">{{ scopeToText(scope) }}</span>
      </div>
      <h6>Apply To Account:</h6>
      <app-ui-dropdown-permission-request [options]="accounts" [current]="preSelectedAccount" (dropdownResponse)="selectedAccount = $event" style="width: 100%;align-self: center;"></app-ui-dropdown-permission-request>
      <div class="seperator"></div>
      <div class="flex-column bottom">
        <button class="blue confirm" (click)="grantPermissions()">Approve</button>
      </div>
    </div>
  </div>
</div>