<div *ngIf="isOpen" class="flex-column kukai-modal">
  <div class="content">
    <div class="flex-column top">
      <div class="flex-row button close" (click)="closeModalAction()"><img class="x"
          src="../../../assets/img/close-btn-x.png" /></div>
      <h1 class="title">Confirmation</h1>
    </div>
    <div class="flex-column confirm-send" [class.image]="tokenBalanceService.isNFT(token)" [class.batch]="parameters">
      <div class="flex-row top">
        <div class="flex-column left" [style.minHeight]="!tokenBalanceService.isNFT(token) ? 'unset' : ''">
          <span *ngIf="!tokenBalanceService.isNFT(token)" class="key">SEND AMOUNT:</span>
          <app-asset class="token-image" [class.balance]="!tokenBalanceService.isNFT(token)"
            [meta]="token ? token.thumbnailAsset || token.displayAsset : '../../../assets/img/tezos-xtz-logo.svg'"
            [class.sd]="!token"></app-asset>
        </div>
        <div class="flex-column right">
          <span class="flex-row amount"><span class="tez value">{{ formatAmount(token, totalAmount(),
              false)
              }}</span>
            <ng-container *ngIf="!tokenTransfer"><span class="fiat key">USD: <span class="value">{{'$' +
                  (totalAmount()
                  *
                  walletService.wallet.XTZrate | number:'1.2-2')
                  }}</span></span>
            </ng-container>
          </span>
          <div class="flex-column items">
            <span *ngIf="tokenBalanceService.isNFT(token)" class="key amount-quantity">QUANTITY:&nbsp;&nbsp;<span
                class="value">{{ getQuantity(totalAmount())
                }}</span></span>
            <span class="key">FEE:&nbsp;&nbsp;<span class="value">{{ (getTotalFee() | number:'1.0-6') + ' tez'
                }}</span></span>
            <span *ngIf="getTotalBurn(); let totalBurn" class="key">STORAGE COST:&nbsp;<span class="value">{{ (totalBurn
                |
                number:'1.0-6') + ' tez'
                }}</span></span>
          </div>
        </div>
      </div>
      <div class="flex-row from">
        <div class="flex-column left">
          <span class="key">FROM: </span>
        </div>
        <div class="flex-column right">
          <div *ngIf="getUsername(); let displayName" class="flex-row torus-name"><img class="verifier-logo"
              src="../../../assets/img/{{ getVerifier() }}-logo.svg">{{ displayName }}</div>
          <span class="address">{{
            activeAccount?.address
            }}</span>
        </div>
      </div>
      <div *ngIf="!parameters" class="flex-row divider">
        <span></span><img class="icon" src="../../../assets/img/send-down-arrow.png" /><span></span>
      </div>
      <div class="flex-row to">
        <div class="flex-column left">
          <span class="key">SEND TO<ng-container
              *ngIf="parameters && transactions?.length > 1">&nbsp;BATCH</ng-container>: </span>
        </div>
        <div class="flex-column right">
          <div *ngFor="let transaction of transactions | slice:0:showAll; index as i"
            (click)="updateParameters(i, transaction.parameters)" class="flex-column transaction">
            <span *ngIf="transaction?.meta && transaction.meta?.verifier" class="flex-row torus-to value"><img
                src="../../../assets/img/{{ transaction.meta.verifier }}-logo.svg" class="icon">
              {{ transaction.meta?.alias }}</span>
            <span class="flex-row single-to" [class.default]="transactions.length === 1"
              [class.selectable]="transactions.length > 1 && parameters"
              [class.active]="batchParamIndex === i && transactions.length > 1 && parameters"><span>{{
                transaction?.destination
                }}</span></span>
            <p class="previewAttention" *ngIf="tokenTransfer && transaction?.destination.slice(0, 3) === 'KT1'">
              Warning:
              Verify the contract can manage tokens</p>
          </div>
          <div *ngIf="transactions?.length > 10" class="button show-all"
            (click)="showAll = showAll === 10 ? undefined : 10">{{ showAll === 10 ? 'Show All' : 'Show Less' }}</div>
        </div>
      </div>
      <br />
      <br />
      <span *ngIf="parameters && transactions.length > 1" class="flex-row transfer-amount"><span class="key">TRANSFER
          AMOUNT: </span><span class="key">{{ (transactions[batchParamIndex]?.amount | number:'1.0-6')
          }}</span></span>
      <div *ngIf="parameters && parameters?.entrypoint" class="flex-row contract-call"><span class="key">CONTRACT CALL:
        </span><span class="key">{{ parameters?.entrypoint }}</span></div>
      <app-ui-toggle-advanced style="width: 13rem;" *ngIf="externalReq" [default]="false"
        (stateChange)="advancedForm = $event">
      </app-ui-toggle-advanced>
      <div *ngIf="advancedForm" class="advanced-form">
        <ng-container *ngIf="parameters">
          <div class="tabs-container">
            <ul class="flex-row tabs">
              <li class="tab" [class.active]="parametersFormat === 0" (click)="setParametersFormat(0)">Micheline</li>
              <li class="tab" [class.active]="parametersFormat === 1" (click)="setParametersFormat(1)">JSON</li>
            </ul>
          </div>
          <textarea *ngIf="parameters" rows="10" readonly>{{ parametersDisplay }}</textarea>
        </ng-container>
        <ng-container *ngIf="externalReq && (!walletService.isLedgerWallet()) || ledgerError">
          <div class="flex-row">
            <div class="flex-column">
              <label class="label">Gas limit</label>
              <input type="text" class="text" (input)="sanitizeNumberInput($event, 'gas')" (paste)="sanitizeNumberInput($event, 'gas')"
                [placeholder]="getTotalDefaultGas() || '0'" [(ngModel)]="customGasLimit">
            </div>
            <div class="flex-column">
              <label class="label">Fee</label>
              <input type="text" class="text" [placeholder]="getTotalFee() || '0'"
                (paste)="sanitizeNumberInput($event)"
                (input)="sanitizeNumberInput($event)" [(ngModel)]="customFee">
            </div>
          </div>
          <div class="flex-row">
            <div class="flex-column">
              <label class="label">Storage limit</label>
              <input type="text" class="text" (input)="sanitizeNumberInput($event, 'storage')" (paste)="sanitizeNumberInput($event, 'storage')"
                [placeholder]="getTotalDefaultStorage() || '0'" [(ngModel)]="customStorageLimit">
            </div>
            <div class="flex-column">
              <label class="label">Max storage cost</label>
              <input type="text" class="text read-only" [placeholder]="(getTotalBurn() || '0') + ' tez'" tabindex=-1
                readonly>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="flex-column bottom">
        <span [style.visibility]="!pwdInvalid ? 'hidden' : ''" [style.margin]="!pwdInvalid ? '0' : ''"
          class="label danger">{{ this.pwdInvalid }}</span>
        <ng-container *ngIf="walletService.isPwdWallet()">
          <span class="label">ENTER YOUR
            PASSWORD TO CONFIRM:</span>
          <input [(ngModel)]="password"
            autocomplete="current-password" placeholder="Password" class="text password" type="password"
            (input)="this.pwdInvalid = ''" (change)="this.pwdInvalid = ''" (keydown.enter)='inject()'>
        </ng-container>
        <button class="confirm" *ngIf="walletService.isLedgerWallet() && ledgerError; else elseBlock"
          (click)="ledgerRetry()">Sign with
          Ledger</button>
        <ng-template #elseBlock>
          <button class="confirm" (click)="inject()"
            [disabled]="(walletService.isLedgerWallet() && (!sendResponse || !sendResponse.payload || !sendResponse.payload.signedOperation)) || walletService.isWatchWallet()">
            {{ walletService.isLedgerWallet() ? 'Broadcast' : 'Confirm'}}</button>
        </ng-template>
      </div>
    </div>
  </div>
</div>