<div [style.display]="!isOpen ? 'none' : ''" class="flex-column kukai-modal">
  <div class="content">
    <div class="flex-column top">
      <div class="flex-row button close" (click)="closeModal()"><img class="x"
          src="../../../assets/img/close-btn-x.png" /></div>
      <h1 class="title">{{ title }}</h1>
    </div>
    <app-account-list-body [current]="activeAccount">
    </app-account-list-body>
  </div>
</div>