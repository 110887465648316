<div class="flex-column settings">
  <a><button class="flex-row back" [routerLink]="'/account/' + activeAccount?.address"><img src="../../../../../assets/img/left-arrow.svg">BACK</button></a>
  <h3>Connect with dApps</h3>
  <div class="seperator"></div>
  <div class="flex-column handler">
    <div>
      <h4>Connect to dApps on <u>another device</u> by scanning a QR code.</h4>
    </div>
    <div class="scan-button">
      <app-qr-scanner></app-qr-scanner>
    </div>
  </div>
  <h3>Peers</h3>
  <div class="seperator"></div>
  <table *ngIf="beaconService.peers.length">
    <thead>
      <tr>
        <th>Name</th>
        <th>Relay server</th>
        <th><span (click)="beaconService.removePeers()">Remove&nbsp;all</span></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let peer of beaconService.peers; index as i">
        <td>{{ peer.name }}</td>
        <td>{{ peer.relayServer }}</td>
        <td (click)="beaconService.removePeer(i)"><img (click)="beaconService.removePeer(i)" src="../../../assets/img/cross.svg"></td>
      </tr>
    </tbody>
  </table>
  <h3>Permissions</h3>
  <div class="seperator"></div>
  <table *ngIf="beaconService.permissions.length">
    <thead>
      <tr>
        <th>Name</th>
        <th>Address</th>
        <th><span (click)="beaconService.removePermissions()">Remove&nbsp;all</span></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let permission of beaconService.permissions; index as i"
        [class.inactive]="!accountAvailable(permission.address)">
        <td>{{ permission.appMetadata.name }}</td>
        <td>{{ formatAddress(permission.address) }}</td>
        <td><img (click)="beaconService.removePermission(i)" src="../../../assets/img/cross.svg"></td>
      </tr>
    </tbody>
  </table>
  <h3>Tokens</h3>
  <div class="seperator"></div>
  <button class="rescan" (click)="rescan()">Rescan unknown tokens</button>
  <button id="all" class="rescan" (click)="rescanAll()">Clear all token metadata</button>
  <h3>RPC</h3>
  <div class="seperator"></div>
  <br />
  <app-ui-generic-dropdown [options]="CONSTANTS.NODE_LIST" [customList]="customRpcList" [current]="rpc || CONSTANTS.NODE_LIST[0]" (dropdownResponse)="dropdownResponse($event)" (dropdownListUpdated)="dropdownListUpdated($event)"></app-ui-generic-dropdown>
</div>