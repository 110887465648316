<div *ngIf="signRequest" class="flex-column kukai-modal">
  <div class="content">
    <div class="flex-column top">
      <div class="flex-row button close" (click)="rejectSigning()"><img class="x" src="../../../assets/img/close-btn-x.png" /></div>
      <h1 class="title">
        <ng-container *ngIf="isMessage">Sign Message</ng-container>
        <ng-container *ngIf="!isMessage">Sign Expression</ng-container>
      </h1>
    </div>
    <div class="flex-column sign-expr">
      <p class="previewAttention">An application has requested you sign a<ng-container *ngIf="isMessage"> message</ng-container><ng-container *ngIf="!isMessage">n expression</ng-container></p>
      <p *ngIf="!isMessage" class="previewAttention warning">Warning! Only sign if you understand the expression or trust the source.</p>
      <app-ui-toggle-advanced *ngIf="!isMessage" style="width: 13rem; margin-bottom: 2rem;" [default]="false" (stateChange)="advancedForm = $event"></app-ui-toggle-advanced>
      <textarea *ngIf="advancedForm || isMessage" rows="10" readonly>{{ payload }}</textarea>
      <div class="seperator" style ="left: 0"></div>
      <div class="flex-column bottom">
        <span *ngIf="this.pwdInvalid" class="danger margin">{{ this.pwdInvalid }}</span>
        <input *ngIf="walletService.isPwdWallet()" [(ngModel)]="password"
          autocomplete="current-password" placeholder="Password" class="text password" type="password"
          (input)="this.pwdInvalid = ''" (change)="this.pwdInvalid = ''" (keydown.enter)='sign()'>
        <button class="purple confirm" (click)="sign()" [disabled]="walletService.isWatchWallet()">
          {{ walletService.isLedgerWallet() ? 'Sign with Ledger' : 'Sign'}}</button>
      </div>
    </div>
  </div>
</div>